/* blog --------------------- */

.blog_container {
    display: flex;
    width: 100%;
    padding: 50px 0 100px 0;
}

.blog_container_left {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.feature_subheading{
    text-align:center

}

.blog_container_right {
    padding: 0 24px 0 40px;
    width: 65%;
}

.blog_content_heading {
    color: #101828;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 20px;
}

.blog_content {
    color: #121330;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    width: 90%;
}

/* blog card */
.blog_card {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 340px;
    aspect-ratio: 1/1.1;
    margin: 10px 10px;
    /* height: 402px; */
}

.blog_card_img_container {
    height: 60%;
    width: 100%;
}

.blog_card_img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: cover;
}

.blog_card_content {
    height: 40%;
    padding: 24px;
    color: #101828;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.blog_card_content>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog_container_right .slick-prev:before,.blog_container_right .slick-next:before {
    color: #226DB4 !important;
    font-size: 34px !important;

}

.blog_container_right .slick-prev {
    left: -42px !important;
}


.blog_container_right .slick-dots li.slick-active button:before {
    color: #226DB4 !important;
}
.blog_container_right .slick-dots li button:before {
    font-size: 12px !important;
}


/*  -------- */

@media only screen and (max-width:1261px) {
    .blog_card_content>p {
        -webkit-line-clamp: 3;
    }

}
@media only screen and (max-width:1087px) {
    .blog_card_content>p {
        -webkit-line-clamp: 2;
    }

}
@media only screen and (max-width:767px) {
    .blog_container {
        flex-direction: column;
        padding: 35px 0 50px 0;
    }

    .blog_container_left,
    .blog_container_right {
        width: 100%;
    }
    .blog_container_left> .blog_content_heading {
        text-align: center;
    }
    .blog_container_left>.blog_content {
    padding-bottom: 35px;
        text-align: center;
    }
}
@media only screen and (max-width:568px) {
    .blog_card_content>p {
        -webkit-line-clamp: 2;
    }

    .blog_card_content {
        padding: 16px;
    }

    .blog_container_right {
        padding: 0;
    }
}

