.banner_bg {
  width: 100%;
  position: relative;
}

.banner_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: calc(100vh - 60px);
  max-height: 690px;
  align-items: center;
}

.banner_container_left,
.banner_container_right {
  width: 46%;
  padding-bottom: 100px;
}
.header_top {
  margin-top: 70px;
}

.banner_container_left > .banner_heading {
  color: var(--text-primary-Color, #101828);
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.banner_container_left > .banner_text {
  color: var(--text-secondary-Color, #121330);
  font-size: 16px;
  font-weight: 500;
  line-height: 180%;
  margin-bottom: 36px;
}

.banner_container_left > .banner_btn_container {
  display: flex;
  gap: 28px;
}
.sponsor_container {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  flex-wrap: wrap;
}

.sponsor_image {
  width: auto;
  height: 40px;
}

.feature_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 120px;
}

.feature_heading {
  color: var(--primary-Color, #226db4);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.feature_subheading {
  color: var(--text-primary-Color, #101828);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.52px;
}

.feature_container > .feature_content {
  color: var(--text-secondary-Color, #121330);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
}

.feature_types_container {
  display: flex;
}

.feature_types_container > .feature_types {
  padding: 34px;
  border-right: 1px solid rgba(55, 57, 63, 0.1);
}

.feature_types_container:last-child .feature_types:last-child {
  border: none;
}

.feature_types_container > .feature_types > .feature_inner_types {
  max-width: 253px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.feature_inner_types > .feature_image {
  width: 72px;
  height: 72px;
}

.feature_inner_types > p {
  color: #1a1a1a;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.category_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.category_card_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
}

.category_card_container > .category_card {
  display: flex;
  width: 383px;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  border-radius: 12px;
  border: 1px solid rgba(216, 216, 216, 0.4);
  background: #fff;
  box-shadow: 0px 67.11439px 109.06088px 0px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.category_card:hover {
  background-color: var(--primary-Color);
  transform: translateY(-9px);
  z-index: 1;
}

.category_card:hover .category_card_header > .category_card_header_text,
.category_card:hover .category_card_text {
  color: var(--white-text-color, #fff);
}

.category_card > .category_card_header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.category_card_header > .category_card_image {
  width: 46px;
  height: 46px;
  border-radius: 10px;
}

.category_card_header > .category_card_header_text {
  color: var(--text-primary-Color, #101828);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.4px;
}

.category_card > .category_card_text {
  color: var(--text-secondary-Color, #121330);
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
}

.self_hosted_container {
  padding-top: 35px;
  padding-bottom: 70px;
}

.self_hosted_content_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 40px 0;
  gap: 40px;
}

.self_hosted_content_left,
.self_hosted_content_right {
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.self_hosted_content_left > p {
  color: var(--text-secondary-Color, #121330);
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 20px;
}

.self_hosted_content_right > .self_hosted_img {
  width: 100%;
  height: auto;
}

.media_section {
  position: relative;
}

.media_section_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: var(--primary-Color, #226db4);
}

.media_outter_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 76px;
}

.media_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.media_subTitle {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.52px;
}

.media_container {
  display: flex;
  gap: 60px 40px;
  flex-wrap: wrap;
  padding: 50px 0 100px 0;
  justify-content: center;
}

.media_header {
  color: var(--text-primary-Color, #101828);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.media_content {
  color: #121330;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.media_card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.media_card_content img {
  max-width: 90px;
}

.media_card {
  aspect-ratio: 1.07/1;
  position: relative;
  display: flex;
  max-width: 365px;
  padding: 0px 24px 28px 24px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0.25px solid #d8d8d8;
  background: var(--white-text-color, #fff);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.03);
  transition: transform 0.3s ease;
}

.media_card:hover {
  transform: translateY(-9px);
  z-index: 1;
}

.media_circle_number {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-Color, #226db4);
  font-size: 18px;
  font-weight: 600;
  border: 1px solid var(--primary-Color, #226db4);
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  margin: 10px;
}

.media_circle {
  position: absolute;
  top: -38px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  background-color: #fff;
}

.media_circle_area {
  position: relative;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer_heading {
  color: #000;
  font-size: 34.528px;
  font-weight: 800;
  line-height: normal;
}

.customer_subheading {
  color: #222;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.customer_content {
  margin-top: 30px;
  color: #121330;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 166.6%;
}

.customer_img_container {
  aspect-ratio: 1.5/1;
  border-radius: 10px;
  background: #fff;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  text-align: center;
}

.customer_img_container > .customer_img {
  height: 100%;
  object-fit: contain;
  display: inline-block;
}

.customer_slider_item {
  border-radius: 20px;
  box-shadow: 0px 20px 100px 0px rgba(21, 21, 21, 0.1);
  display: flex;
  gap: 40px;
  padding: 60px 100px;
  width: 100%;
}

.customer_slider_item > div {
  width: 50%;
}

.customer_item_color_1 {
  background: #a8dff2;
}

.customer_item_color_2 {
  background: #ffebff;
}

.customer_item_color_3 {
  background: #a8dff2;
}

.customer_content_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.customer_slider_container {
  padding: 40px 0 100px 0;
}

.banner_image {
  width: 568px;
  height: 479px;
  height: auto;
  object-fit: contain;
}
.banner_video {
  width: 523px;
  top: 26px;
  height: auto;
  object-fit: contain;
  position: absolute;
  left: 24px;
  right: 35px;
  border-radius: 16px;
  aspect-ratio: unset;
}
.backgroud_video {
  position: relative;
  margin-top: 140px;
}
.banner_video {
  position: absolute;
}

.withsubmenunav .mainspan:after {
  position: absolute;
  top: 8px;
  right: 0;
  background: no-repeat bottom center
    url("../Helper/images/header_menudrop.svg");
  width: 10px;
  height: 5px;
  content: "";
}

.withsubmenunav .mainspan.currentPage:after {
  position: absolute;
  top: 8px;
  right: 0;
  background: no-repeat bottom center
    url("../Helper/images/header_menudropcurrent.svg");
  width: 10px;
  height: 5px;
  content: "";
}

.featureBox_section {
  display: flex;
  gap: 40px;
  margin-bottom: 70px;
}

.featureBox_section > .featureBox_left {
  flex: 0 1 calc(50% - 10px);
  width: 50%;
}

.featureBox_section > .featureBox_right {
  flex: 0 1 calc(44% - 10px);
  width: 50%;
}

.featureBox_left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pic-holder {
  height: 100%;
}

.pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature_left_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--BlinkBee-primary-Color, #226db4);
  background: #fff;
  padding: 30px 20px;
}

.feature_left_card > .heading {
  color: var(--primary-Color, #226db4);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.4px;
}

.feature_left_card > .subheading {
  color: var(--primary-Color, #226db4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.feature_left_card.active,
.feature_left_card:hover {
  background: linear-gradient(90deg, #226db4 0%, #089949 100%);
}
.feature_left_card.active > .heading,
.feature_left_card.active > .subheading,
.feature_left_card:hover > .heading,
.feature_left_card:hover > .subheading {
  color: #fff;
}

.industry_bg {
  margin-top: 40px;
  background: no-repeat top center url("../Helper/images/industrypage_Bg.svg");
  width: 100%;
  min-height: 540px;
}

.industry_heading {
  color: #fff;
  text-align: center;
}

.industry_section {
  padding: 30px 0 30px 0;
}

.industry_section > .feature_content {
  max-width: 1132px;
  margin: 0 auto;
  margin-top: 10px;
}

.industry_section > .feature_content.industry_heading {
  line-height: 180%;
  max-width: 800px;
}

.industry_cat_section {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 10px 30px 45px;
  margin-top: 30px;
}

.industry_cat_nav {
  display: flex;
  padding: 0 20px;
  gap: 33px;
  overflow: scroll;
  margin-bottom: 50px;
}

.industry_cat_nav::-webkit-scrollbar {
  display: none;
}

.industry_cat_nav > p {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.36px;
  white-space: nowrap;
  cursor: pointer;
}

.industry_cat_nav > p.active {
  color: var(--primary-Color, #226db4);
  border-bottom: 1px solid var(--primary-Color);
}

.industry_container {
  display: flex;
  gap: 20px;
}

.industry_container .industry_left_content,
.industry_container .industry_right_content {
  width: 50%;
}

.industry_right_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.industry_left_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.industry_left_content > .heading {
  color: #101828;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.5px;
}

.industry_left_content > .subheading {
  color: #121330;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.industry_left_content > .btn_container {
  display: flex;
  gap: 30px;
}

.video_container {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0 50px 0;
}

.video-section {
  background: #f6f6f6;
  padding: 30px 0 10px 0;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .banner_video {
    width: 100%;
    top: 26px;
    height: auto;
    object-fit: contain;
    position: absolute;
    left: 24px;
    right: 35px;
  }
  .banner_image {
    width: 100%;
    height: 479px;
    height: auto;
    object-fit: contain;
  }
  .industry_cat_section{padding:30px 10px 30px 25px}
  .industry_section{padding:30px 16px}
}

@media only screen and (max-width: 925px) {
  .banner_bg {
    background-position: top;
  }

  .media_section_bg {
    height: 27%;
  }

  .customer_slider_item {
    padding: 40px 60px;
    flex-direction: column;
  }

  .customer_slider_item > div {
    width: 100%;
  }
  .industry_container{flex-wrap: wrap;flex-direction: column-reverse;}
  .industry_container .industry_left_content, .industry_container .industry_right_content{width:100%;text-align: center;}
}

@media only screen and (min-width: 768px) {
.category_card_container > .category_card.category_cardoffcard{width:calc(50% - 11px)}
}
@media only screen and (max-width: 767px) {
  .banner_container {
    flex-direction: column-reverse;
    max-height: fit-content;
    height: fit-content;
  }

  .banner_container_left {
    width: 100%;
    padding: 50px 0;
  }

  .banner_container_right {
    width: 100%;
    padding: 0 0 50px 0;
  }

  .media_section_bg {
    height: 14%;
  }

  .feature_subheading {
    margin-top: 10px;
  }

  .self_hosted_content_container {
    flex-direction: column;
  }

  .self_hosted_content_left,
  .self_hosted_content_right {
    width: 100%;
  }

  .self_hosted_content_left > div {
    width: 100%;
    text-align: center;
  }
}


@media only screen and (max-width: 723px) {
  .customer_slider_item {
    padding: 20px 40px;
  }
  .banner_image {
    width: 100%;
    height: 479px;
    height: auto;
    object-fit: contain;
    display: none;
  }
}

@media only screen and (max-width: 568px) {
  .feature_types_container {
    flex-direction: column;
  }

  .banner_container_left > .banner_btn_container {
    gap: 13px;
  }

  .banner_container_right {
    min-height: 332px;
    padding: 24px 0 24px 0;
  }

  .banner_container_left {
    padding: 0 0;text-align: center;
  }

  .feature_types_container > .feature_types {
    border-right: none;
    border-bottom: 1px solid rgba(55, 57, 63, 0.1);
  }

  .media_card {
    width: 100%;
    padding: 0px 16px 24px 16px;
  }

  .media_container {
    padding-bottom: 50px;
  }

  .media_section_bg {
    height: 16%;
  }

  .feature_subheading {
    margin-top: 5px;
  }

  .customer_slider_item {
    padding: 16px 16px;
    gap: 24px;
  }

  .customer_img_container {
    width: 95%;
    height: 95%;
  }

  .custom_carousel > div > .rec-arrow-left {
    position: absolute;
    z-index: 1;
  }

  .custom_carousel > div > .rec-arrow-right {
    position: absolute;
    z-index: 1;
    right: 30px;
  }

  .sponsor_container {
    margin-top: 50px;
    justify-content: space-around;
  }

  .feature_container {
    margin-top: 20px;
    margin-bottom: 24px;
    gap: 7px;
  }
  .header_video {
    height: 100% !important;
  }

  .banner_container_left > .banner_heading,
  .feature_subheading,
  .media_subTitle,
  .customer_heading,
  .faq_subheading,
  .blog_content_heading {
    font-size: 25px;
    line-height: 34px;
    margin-top: 30px;
  }

  .faq_heading {
    margin-bottom: 5px;
  }

  .banner_container_left > .banner_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 170%;
    margin-bottom: 30px;
  }

  .category_container {
    gap: 0px;
  }

  .category_card_container > .category_card {
    padding: 20px 16px;
  }

  .feature_inner_types > p {
    font-size: 16px;
  }

  .self_hosted_container {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .media_outter_container {
    padding-top: 40px;
  }

  .self_hosted_content_container {
    gap: 30px;
    margin: 24px 0 0 0;
  }

  .customer_content {
    margin-top: 20px;
  }

  .customer_slider_container {
    padding: 24px 0 50px 0;
  }

  .faq_container {
    margin-top: 24px;
    margin-bottom: 35px;
  }

  .blog_container {
    padding: 0px 0 30px 0;
  }

  .banner_bg {
    padding-bottom: 30px;
  }

  .customer_subheading {
    margin-top: 2px;
  }

  .blog_container_left > .blog_content {
    padding-bottom: 15px;
  }

  .blog_content_heading {
    margin-bottom: 10px;
  }

  .customer_img_container > .customer_img {
    width: 90%;
  }

  .featureBox_section {
    flex-direction: column;
  }

  .featureBox_section > .featureBox_left,
  .featureBox_section > .featureBox_right {
    width: 100%;
  }
  .banner_video {
    width: 97%;
    top: 13px;
    height: auto;
    object-fit: contain;
    position: absolute;
    left: 5px;
    right: 1px;
  }
  .banner_image {
    width: 100%;
    height: 479px;
    height: auto;
    object-fit: contain;
    display: none;
  }
  .backgroud_video {
    margin-top: 0;
  }
  .header_top {
    margin-top: 0;
  }
  
  .industry_cat_section{padding:15px}
  .industry_cat_nav{white-space: nowrap;max-width: 1200px;overflow-x: scroll;}
}

@media screen and (max-width: 435px) {
  .banner_btn_container {
    flex-direction: column;
  }

  .self_hosted_content_left > div > .btn-primary-primary {
    width: 100%;
  }

  .banner_container_right {
    min-height: 285px;
  }
}

@media screen and (max-width: 367px) {
  .container {
    padding: 0 12px;
  }

  .banner_container_right {
    min-height: 259px;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 2/1.3;
  border-radius: 15px;
}

#recaptcha_image img {
  width: 200px;
}

#rc-imageselect {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
