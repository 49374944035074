.faq_heading {
  color: var(--primary-Color, #226db4);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.faq_subheading {
  color: var(--text-primary-Color, #101828);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.52px;
}

.faq_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 120px;
}

.faq_Item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid rgba(133, 18, 223, 0.1);
  max-width: 950px;
  width: 100%;

  overflow: hidden;
}

.faq_Item_question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.faq_Item_question > .faq_question {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;

  color: #101828;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.faq_answer {
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;

  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    padding-bottom 0.4s ease-in-out;
}

.faq_Item.open .faq_answer {
  max-height: fit-content;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    padding-bottom 0.4s ease-in-out;
  padding-bottom: 20px;
}
